import { ColumnType } from 'antd/lib/table'
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface'
// import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { fullName } from '@publica/common'
import { participantTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC, useDebouncedAsyncCallback, usePaginationConfig } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'

import { LegalEntityType, useGetOperationPaginatedParticipantsQuery } from '../../../../../data'
import { participantsInvitationStatusState, selectedParticipantsState } from '../state'
import { Operation, Participant, ParticipantInvitationStatus as TParticipantInvitationStatus } from '../types'
import { ParticipantActions } from './ParticipantActions'
import { participantGoTo } from './ParticipantGoTo'
import { ParticipantInvitationStatus } from './ParticipantInvitationStatus'
import { ParticipantStatusTags } from './ParticipantStatusTags'

type ParticipantsTableProps = {
    operation: Operation
}

type ParticipantTableFilters = {
    code?: string[]
    legalEntityType?: LegalEntityType[]
    name?: string[]
    state?: (typeof participantStatusFilters)[number][]
}

const participantStatusFilters = [
    'hasMissingValues',
    'hasMissingAttachments',
    'hasPendingSignatures',
    'pendingInvitation',
] as const

const participantTypeFilters = ['INDIVIDUAL', 'COMPANY'] as const

type ParticipantStatus = (typeof participantStatusFilters)[number]

const useParticipantsTableTranslation = createUseTranslation({
    EN: {
        actions: 'Actions',
        code: 'Code',
        participant: 'Participant',
        state: 'State',
        type: 'Type',
        account: 'Represented by',
    },
    FR: {
        actions: 'Actions',
        code: 'Code',
        participant: 'Participant',
        state: 'État',
        type: 'Type',
        account: 'Représenté par',
    },
})

const useParticipantStatusFilterLabelTranslation = createUseTranslation({
    EN: {
        hasPendingSignatures: 'Documents are awaiting signature',
        hasMissingAttachments: 'KYC documents are missing',
        hasMissingValues: 'Some personal information is missing',
        pendingInvitation: 'Invitation not yet sent',
    },
    FR: {
        hasPendingSignatures: 'Des documents sont en attente de signature',
        hasMissingAttachments: 'Des documents KYC sont manquants',
        hasMissingValues: "La fiche d'informations personnelles est incomplète",
        pendingInvitation: "Pas encore invité à l'opération",
    },
})

const ParticipantStatusFilterLabel = ({ status }: { status: ParticipantStatus }) => {
    const { t } = useParticipantStatusFilterLabelTranslation()
    return <>{t(status)}</>
}

export const ParticipantsTable: FC<ParticipantsTableProps> = ({ operation }) => {
    const { data, loading, fetchMore } = useGetOperationPaginatedParticipantsQuery({
        variables: {
            operationId: operation.id,
        },
        notifyOnNetworkStatusChange: true,
    })

    const { t } = useParticipantsTableTranslation()
    const locale = useCurrentLocale()

    const [selectedParticipants, setSelectedParticipants] = useRecoilState(selectedParticipantsState)
    const participantsInvitationStatus = useRecoilValue(participantsInvitationStatusState)

    const participants = useMemo(
        () =>
            sortBy(
                (data?.operation?.participantsConnection.edges ?? []).map(edge => edge.node),
                participant => participant.info.code
            ),
        [data?.operation?.participantsConnection.edges]
    )

    const columns = useMemo<(FilterColumnType<Participant> | ColumnType<Participant>)[]>(
        () => [
            {
                render: (_, participant) => <ParticipantInvitationStatus participant={participant} />,
                align: 'center',
                width: 50,
            },
            {
                title: t('code'),
                key: 'code',
                render: (_, participant) => participantGoTo(participant, participant.info.code),
                filterValue: true,
                align: 'left',
                width: 110,
            },
            {
                title: t('participant'),
                key: 'name',
                render: (_, participant) => participantGoTo(participant),
                filterValue: participant => participant.info.name,
                width: 400,
            },
            {
                title: t('type'),
                render: (_, participant) => participantTypeLookup.labelForKeyAndLocale(participant.type, locale),
                key: 'legalEntityType',
                filters: participantTypeFilters.map(type => ({
                    text: participantTypeLookup.labelForKeyAndLocale(type, locale),
                    value: type,
                })),
                width: 180,
            },
            {
                title: t('account'),
                render: (_, participant) => fullName(participant.legalEntity.representative),
                width: 400,
            },
            {
                title: t('state'),
                key: 'state',
                render: (_, participant) => <ParticipantStatusTags participant={participant} />,
                filters: participantStatusFilters.map(status => ({
                    text: <ParticipantStatusFilterLabel status={status} />,
                    value: status,
                })),
            },
            {
                title: t('actions'),
                render: (_, participant) => <ParticipantActions participant={participant} operation={operation} />,
                align: 'right',
                width: 250,
            },
        ],
        [operation, t, locale]
    )

    const rowSelection: TableRowSelection<Participant> = useMemo(
        () => ({
            onChange: (_, participants) => {
                setSelectedParticipants(participants)
            },
            selectedRowKeys: selectedParticipants
                .filter(participant =>
                    participantIsSelectableAccordingToState(participantsInvitationStatus[participant.id])
                )
                .map(p => p.id),
            getCheckboxProps: ({ id }) => ({
                disabled: !participantIsSelectableAccordingToState(participantsInvitationStatus[id]),
            }),
        }),
        [participantsInvitationStatus, selectedParticipants, setSelectedParticipants]
    )

    const pagination = usePaginationConfig(data?.operation?.participantsConnection.pageInfo)

    const onChange = useDebouncedAsyncCallback(
        async (pagination: TablePaginationConfig, filters: ParticipantTableFilters) => {
            const { pageSize, current } = pagination

            const { code, state, name, legalEntityType } = filters

            const statuses = (state ?? []).reduce(
                (statuses, state) => ({ ...statuses, [state]: true }),
                {} as Partial<Record<ParticipantStatus, boolean>>
            )

            await fetchMore({
                variables: {
                    page: Math.max((current ?? 1) - 1, 0),
                    pageSize,
                    filters: {
                        code,
                        name,
                        legalEntityType,
                        ...statuses,
                    },
                },
            })
        },
        [fetchMore]
    )

    return (
        <FilterTable
            rowSelection={rowSelection}
            dataSource={participants}
            rowKey="id"
            loading={loading}
            columns={columns}
            pagination={pagination}
            onChange={onChange}
        />
    )
}

const participantIsSelectableAccordingToState = (state: TParticipantInvitationStatus | undefined) =>
    state === undefined ? true : !state.isCooling && !state.isSending
