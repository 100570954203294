import { Card, Form, Input, InputProps, Modal } from 'antd'
import IMask from 'imask'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, OperationHeader, OperationTable, OperationTableProps, icons } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'
import { ArrayItem, assert } from '@publica/utils'

import {
    GetOperationsDocument,
    useCheckOperationCodeLazyQuery,
    useCheckOperationNameLazyQuery,
    useCreateOperationMutation,
    useGetOperationsQuery,
} from '../../../../data'

// FIXME(admin-ui): refactor Operation Index

type Operation = ArrayItem<OperationTableProps['operations']>

const title = <OperationHeader />

export const OperationIndex: FC = () => {
    const { loading, data } = useGetOperationsQuery({ pollInterval: __SLOW_POLLING__ })

    const [operations, setOperations] = useState<Operation[]>([])

    useEffect(() => {
        if (!loading && data !== undefined) {
            const { operations } = data
            setOperations(operations)
        }
    }, [loading, data])

    return (
        <Card title={title}>
            <OperationControls />
            <OperationTable loading={loading} operations={operations} />
        </Card>
    )
}

type NewOperationForm = {
    name: string | undefined
    fromAddress: string | undefined
    code: string | undefined
}

const useOperationControlsTranslation = createUseTranslation({
    FR: {
        alreadyOperationWithName: `Il existe déjà une opération nommée "{{name}}`,
        alreadyOperationWithCode: `Il existe déjà une opération avec le code "{{code}}"`,
        createOperation: 'Créer une opération',
        name: 'Nom',
        code: 'Code',
        operationEmail: "Email associé à l'opération",
        operationEmailNote: `Cette adresse sera utilisée pour envoyer les emails aux participants de l'opération`,
    },
    EN: {
        alreadyOperationWithName: `There is already an operation named "{{name}}`,
        alreadyOperationWithCode: `There is already an operation using the code "{{code}}"`,
        createOperation: 'Create an operation',
        name: 'Name',
        code: 'Code',
        operationEmail: 'Operation email address',
        operationEmailNote: `This address will be used to send emails on behalf of the operation`,
    },
})

const OperationControls: FC = () => {
    const styles = utils.useControlsStyles()
    const formStyles = utils.useFormStyles()
    const [open, setOpen] = useState(false)
    const [codeSetManually, setCodeSetManually] = useState(false)
    const [form] = Form.useForm<NewOperationForm>()
    const [createOperationMutation, { loading }] = useCreateOperationMutation()
    const [checkOperationNameQuery] = useCheckOperationNameLazyQuery()
    const [checkOperationCodeQuery] = useCheckOperationCodeLazyQuery()
    const navigate = useNavigate()
    const { t } = useOperationControlsTranslation()

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    const close = useCallback(() => {
        setOpen(false)
        form.resetFields()
    }, [form])

    const create = useCallback(async () => {
        const { name, fromAddress, code } = form.getFieldsValue()

        assert.defined(name)
        assert.defined(fromAddress)
        assert.defined(code)

        return createOperationMutation({
            variables: {
                operation: {
                    name,
                    fromAddress,
                    code,
                },
            },
            refetchQueries: [GetOperationsDocument],
        }).then(({ data }) => {
            const id = data?.createOperation.id
            if (!isNil(id)) {
                navigate(id)
            }
        })
    }, [createOperationMutation, form, navigate])

    const onOk = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }
        await create()
    }, [create, form])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkOperationName = useCallback(
        debounce((name: string, callback: (error?: string) => void): void => {
            void (async () => {
                const { data } = await checkOperationNameQuery({
                    variables: {
                        name,
                    },
                })

                if (!isNil(data?.operationByName?.id)) {
                    callback(t('alreadyOperationWithName', { name }))
                    return
                }

                callback()
            })()
        }, 500),
        [checkOperationNameQuery]
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkOperationCode = useCallback(
        debounce((code: string, callback: (error?: string) => void): void => {
            void (async () => {
                const { data } = await checkOperationCodeQuery({
                    variables: {
                        code,
                    },
                })

                if (!isNil(data?.operationByCode?.id)) {
                    callback(t('alreadyOperationWithCode', { code }))
                    return
                }

                callback()
            })()
        }, 500),
        [checkOperationNameQuery]
    )

    const rules = useCommonRules()

    const validationRules = useMemo<FormRules<NewOperationForm>>(
        () => ({
            fromAddress: rules.requiredEmail,
            name: [
                ...rules.required,
                {
                    validator: async (_, name) =>
                        new Promise<void>((resolve, reject) => {
                            checkOperationName(name, error => {
                                if (error !== undefined) {
                                    reject(error)
                                } else {
                                    resolve()
                                }
                            })
                        }),
                },
            ],
            code: [
                ...rules.required,
                {
                    validator: async (_, code) =>
                        new Promise<void>((resolve, reject) => {
                            checkOperationCode(code, error => {
                                if (error !== undefined) {
                                    reject(error)
                                } else {
                                    resolve()
                                }
                            })
                        }),
                },
            ],
        }),
        [checkOperationCode, checkOperationName, rules.required, rules.requiredEmail]
    )

    const name = Form.useWatch('name', form)

    const normalizeCode = useCallback((val: string) => {
        // TODO: replace with react hook
        const normal = val.trim().toUpperCase().replaceAll(/\s/g, '')

        const mask = IMask.createMask({
            mask: '[################]',
            definitions: {
                '#': /[A-Z0-9]/,
            },
        })

        mask.resolve(normal)

        return mask.value
    }, [])

    useEffect(() => {
        if (name !== undefined && !codeSetManually) {
            form.setFieldsValue({ code: normalizeCode(name) })
        }
    }, [name, form, normalizeCode, codeSetManually])

    const onManualCodeChange = useCallback(() => {
        setCodeSetManually(true)
    }, [])

    return (
        <div className={styles.controls}>
            <ActionButton size="middle" icon={icons.AddFolder} onClick={onClick}>
                {t('createOperation')}
            </ActionButton>
            <Modal
                open={open}
                onCancel={close}
                cancelText={t('cancel')}
                title={t('createOperation')}
                confirmLoading={loading}
                okText={t('create')}
                onOk={onOk}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label={t('name')}
                        hasFeedback
                        validateFirst={true}
                        rules={validationRules.name}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="code"
                        label={t('code')}
                        hasFeedback
                        validateFirst={true}
                        rules={validationRules.code}
                        normalize={normalizeCode}
                    >
                        <MaybeManualInput onManualChange={onManualCodeChange} />
                    </Form.Item>
                    <Form.Item label={t('operationEmail')} required hasFeedback>
                        <div className={formStyles.formLabelNote}>{t('operationEmailNote')}</div>
                        {/* TODO(admin-ui): add email validation to create operation
                         */}
                        <Form.Item name="fromAddress" hasFeedback rules={validationRules.fromAddress} noStyle>
                            <Input />
                        </Form.Item>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

type MaybeManualInputProps = InputProps & {
    onManualChange?: (value: string) => void
}

const MaybeManualInput: FC<MaybeManualInputProps> = ({ onChange, onFocus, onBlur, onManualChange, ...props }) => {
    const [focused, setFocused] = useState(false)

    const onLocalFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
        ev => {
            setFocused(true)
            if (onFocus !== undefined) {
                onFocus(ev)
            }
        },
        [onFocus]
    )

    const onLocalBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
        ev => {
            setFocused(false)
            if (onBlur !== undefined) {
                onBlur(ev)
            }
        },
        [onBlur]
    )

    const onLocalChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        ev => {
            if (focused) {
                if (onManualChange !== undefined) {
                    onManualChange(ev.target.value)
                }
            }

            if (onChange !== undefined) {
                onChange(ev)
            }
        },
        [focused, onChange, onManualChange]
    )

    return <Input onFocus={onLocalFocus} onBlur={onLocalBlur} onChange={onLocalChange} {...props} />
}
