import { FileOutlined, FormOutlined, MailOutlined, PaperClipOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, ButtonProps, Card, Popconfirm, Tabs, TabsProps } from 'antd'
import isNil from 'lodash/isNil'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useMatch, useNavigate, useParams, useResolvedPath } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, NotFound, OperationHeader, Spinner, Tab } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { assert } from '@publica/utils'

import { OperationStatus, useChangeOperationStatusMutation, useGetOperationQuery } from '../../../../data'
import { Attachments } from '../../attachments'
import { Documents } from '../../documents'
import { Emails } from '../../emails'
import { Fields } from '../../fields'
import { Participants } from '../../participants'

// TODO(admin-ui): edit operation details

type TabKey = 'participants' | 'documents' | 'emails' | 'attachments' | 'fields'

const useRedirectFromRootToParticipants = () => {
    const rootResolved = useResolvedPath('')
    const isRoot = useMatch({ path: rootResolved.pathname, end: true }) !== null

    const navigate = useNavigate()

    useEffect(() => {
        if (isRoot) {
            navigate('participants')
        }
    }, [isRoot, navigate])
}

const useIsRoute = (path: TabKey): boolean => {
    const resolved = useResolvedPath(path)

    return (
        useMatch({
            path: resolved.pathname,
            end: false,
        }) !== null
    )
}

const useActiveTab = (): TabKey | undefined => {
    const isParticipants = useIsRoute('participants')
    const isDocuments = useIsRoute('documents')
    const isEmails = useIsRoute('emails')
    const isAttachments = useIsRoute('attachments')
    const isFields = useIsRoute('fields')

    if (isParticipants) {
        return 'participants'
    } else if (isDocuments) {
        return 'documents'
    } else if (isEmails) {
        return 'emails'
    } else if (isAttachments) {
        return 'attachments'
    } else if (isFields) {
        return 'fields'
    }

    return
}

type OnTabChange = (key: string) => void

const useTabNavigation = (): OnTabChange => {
    const navigate = useNavigate()

    return useCallback<OnTabChange>(
        key => {
            switch (key as TabKey) {
                case 'participants':
                case 'documents':
                case 'emails':
                case 'attachments':
                case 'fields':
                    navigate(key)
                    break
            }
        },
        [navigate]
    )
}

const useTabsTranslation = createUseTranslation({
    EN: {
        participants: 'Participants',
        attachments: 'KYC Documents',
        documents: 'Documents',
        emails: 'Emails',
        fields: 'Fields',
    },
    FR: {
        participants: 'Participants',
        attachments: 'Documents KYC',
        documents: 'Documents',
        emails: 'Emails',
        fields: 'Champs actifs',
    },
})

const ParticipantsTab: FC = () => {
    const { t } = useTabsTranslation()
    const icon = useMemo(() => <UserOutlined />, [])
    return <Tab icon={icon} label={t('participants')} />
}

const DocumentsTab: FC = () => {
    const { t } = useTabsTranslation()
    const icon = useMemo(() => <FileOutlined />, [])
    return <Tab icon={icon} label={t('documents')} />
}

const EmailsTab: FC = () => {
    const { t } = useTabsTranslation()
    const icon = useMemo(() => <MailOutlined />, [])
    return <Tab icon={icon} label={t('emails')} />
}

const FieldsTab: FC = () => {
    const { t } = useTabsTranslation()
    const icon = useMemo(() => <FormOutlined />, [])
    return <Tab icon={icon} label={t('fields')} />
}

const AttachmentsTab: FC = () => {
    const { t } = useTabsTranslation()
    const icon = useMemo(() => <PaperClipOutlined />, [])
    return <Tab icon={icon} label={t('attachments')} />
}

const useShowOperationTranslation = createUseTranslation({
    FR: {
        operationNotFound: 'Opération non trouvée',
    },
    EN: {
        operationNotFound: 'Operation not found',
    },
})

export const ShowOperation: FC = () => {
    useRedirectFromRootToParticipants()
    const activeTab = useActiveTab()
    const onTabChange = useTabNavigation()

    const { t } = useShowOperationTranslation()

    const { operationId } = useParams()
    assert.defined(operationId, 'Undefined operation ID')

    const { data, loading } = useGetOperationQuery({ variables: { operationId } })
    const operation = data?.operation

    const title = useMemo(() => {
        if (!isNil(operation)) {
            return (
                <OperationHeader operation={operation}>
                    <OperationStatusControl operation={operation} />
                </OperationHeader>
            )
        }

        return undefined
    }, [operation])

    const items = useMemo((): NonNullable<TabsProps['items']> => {
        if (isNil(operation)) {
            return []
        }

        return [
            {
                key: 'participants',
                label: <ParticipantsTab />,
                children: <Participants operation={operation} />,
            },
            {
                key: 'documents',
                label: <DocumentsTab />,
                children: <Documents operation={operation} />,
            },
            {
                key: 'emails',
                label: <EmailsTab />,
                children: <Emails operation={operation} />,
            },
            {
                key: 'fields',
                label: <FieldsTab />,
                children: <Fields operation={operation} />,
            },
            {
                key: 'attachments',
                label: <AttachmentsTab />,
                children: <Attachments operation={operation} />,
            },
        ]
    }, [operation])

    if (loading || data === undefined) {
        return <Spinner />
    }

    if (isNil(operation) || isNil(activeTab)) {
        return <NotFound title={t('operationNotFound')} path=".." card />
    }

    return (
        <Card title={title}>
            <Tabs defaultActiveKey="participants" activeKey={activeTab} onTabClick={onTabChange} items={items} />
        </Card>
    )
}

type Operation = {
    id: string
    name: string
    status: OperationStatus
}

type OperationStatusControlProps = {
    operation: Operation
}

const useOperationStatusControlTranslation = createUseTranslation({
    EN: {
        open: 'Start the operation',
        openConfirm: 'Do you want to start the operation?',
        irreversible: 'This is irreversible',
        reOpen: 'Re-open the operation',
        reOpenConfirm: 'Do you want to re-open the operation?',
        close: 'Close the operation',
        closeConfirm: 'Do you want to close the operation?',
        reversible: 'This is reversible',
        confirm: 'Confirm',
    },
    FR: {
        open: `Lancer l'opération`,
        openConfirm: `Souhaitez-vous lancer l'opération ?`,
        irreversible: 'Cette action est irréversible',
        reOpen: "Ré-ouvrir l'opération",
        reOpenConfirm: "Souhaitez-vous ré-ouvrir l'opération ?",
        close: `Clôturer l'opération`,
        closeConfirm: `Souhaitez-vous clôturer l'opération ?`,
        reversible: 'Cette action est réversible',
        confirm: 'Confirmer',
    },
})

const OperationStatusControl: FC<OperationStatusControlProps> = ({ operation }) => {
    const styles = utils.useConfirmationStyles()
    const [changeOperationStatusMutation, { loading }] = useChangeOperationStatusMutation()
    const [confirmationIsOpen, setConfirmationIsOpen] = useState(false)
    const { t } = useOperationStatusControlTranslation()

    const [label, status, title] = useMemo<[string, OperationStatus, ReactNode]>(() => {
        switch (operation.status) {
            case 'DRAFT':
                return [
                    t('open'),
                    'OPEN',
                    <>
                        <p>{t('openConfirm')}</p>
                        <Alert
                            type="warning"
                            message={t('irreversible')}
                            className={styles.confirmationAlert}
                            showIcon
                        />
                    </>,
                ]
            case 'CLOSED':
                return [t('reOpen'), 'OPEN', t('reOpenConfirm')]
            case 'OPEN':
                return [
                    t('close'),
                    'CLOSED',
                    <>
                        <p>{t('closeConfirm')}</p>
                        <Alert type="info" message={t('reversible')} className={styles.confirmationAlert} showIcon />
                    </>,
                ]
        }
    }, [operation.status, styles.confirmationAlert, t])

    const displayConfirmation = useCallback(() => {
        setConfirmationIsOpen(true)
    }, [])

    const hideConfirmation = useCallback(() => {
        setConfirmationIsOpen(false)
    }, [])

    const changeOperationStatus = useCallback(() => {
        hideConfirmation()
        void changeOperationStatusMutation({
            variables: {
                id: operation.id,
                status,
            },
        })
    }, [changeOperationStatusMutation, hideConfirmation, operation.id, status])

    const okButtonProps = useMemo<ButtonProps>(() => ({ loading }), [loading])

    return (
        <Popconfirm
            title={title}
            open={confirmationIsOpen}
            placement={'bottom'}
            onCancel={hideConfirmation}
            onConfirm={changeOperationStatus}
            okText={t('confirm')}
            okButtonProps={okButtonProps}
            icon={null}
            overlayClassName={styles.confirmation}
        >
            <ActionButton size="middle" inProgress={confirmationIsOpen || loading} onClick={displayConfirmation}>
                {label}
            </ActionButton>
        </Popconfirm>
    )
}
