import { Space, Switch } from 'antd'
import { ExpandableConfig } from 'antd/lib/table/interface'
import orderBy from 'lodash/orderBy'
import reject from 'lodash/reject'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { humanCreatedAt } from '@publica/ui-common-labels'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import {
    ActionButton,
    EmailActionsProps,
    EmailControlsProps,
    EmailStatusTags,
    EmailTable,
    EmailTableProps,
    FilterColumnType,
    FilterTable,
    icons,
} from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { ArrayItem } from '@publica/utils'

import * as graphql from '../../../../data'
import { useArchiveEmailSetsMutation, useUnarchiveEmailSetsMutation } from '../../../../data'
import { EmailActions, EmailControls } from '../../../components'

type EmailSet = Pick<graphql.EmailSet, 'subject' | 'createdAt' | 'id' | 'isArchived'> & { emails: Email[] }
type Email = ArrayItem<EmailTableProps['emails']>

type Operation = Pick<graphql.Operation, 'id' | 'status'>

export type EmailsIndexProps = {
    operation: Operation
    loading: boolean
    emailSets: EmailSet[]
}

// TODO(pagination): paginate emails

export const EmailsIndex: FC<EmailsIndexProps> = ({ operation, loading, emailSets }) => {
    return (
        <>
            <EmailSetControls operation={operation} />
            <EmailSetTable operation={operation} loading={loading} emailSets={emailSets} />
        </>
    )
}

type EmailSetControlsProps = {
    operation: Operation
}

const useEmailSetControlsTranslation = createUseTranslation({
    EN: {
        generate: 'Generate an email',
    },
    FR: {
        generate: 'Générer un email',
    },
})

const EmailSetControls: FC<EmailSetControlsProps> = ({ operation }) => {
    const mutable = operationIsOpen(operation)
    const navigate = useNavigate()
    const controlStyles = utils.useControlsStyles()
    const { t } = useEmailSetControlsTranslation()

    const onClick = useCallback(() => {
        navigate('new')
    }, [navigate])

    return (
        <div className={controlStyles.controls}>
            <ActionButton size="middle" onClick={onClick} disabled={!mutable} icon={icons.AddFile}>
                {t('generate')}
            </ActionButton>
        </div>
    )
}

type EmailSetTableProps = {
    operation: Operation
    loading: boolean
    emailSets: EmailSet[]
}

const useEmailSetTableTranslation = createUseTranslation({
    EN: {
        subject: 'Subject',
        quantity: 'Number of emails',
        state: 'State',
        actions: 'Actions',
    },
    FR: {
        subject: 'Objet',
        quantity: `Quantité d'emails`,
        state: 'État',
        actions: 'Actions',
    },
})

const EmailSetTable: FC<EmailSetTableProps> = ({ operation, loading, emailSets }) => {
    const mutable = operationIsOpen(operation)
    const { t } = useEmailSetTableTranslation()

    const expandable: ExpandableConfig<EmailSet> = useMemo(
        () => ({
            expandedRowRender: (emailSet: EmailSet) => <EmailTableForEmailSet emailSet={emailSet} mutable={mutable} />,
        }),
        [mutable]
    )

    const [showArchivedEmailSets, setShowArchivedEmailSets] = useState(false)

    const setShowArchiveEmailSetsFilter = useCallback((value: boolean) => {
        setShowArchivedEmailSets(value)
    }, [])

    const showArchivedEmailSetsFilter = useCallback(() => {
        return <ShowArchivedEmailSetsControl checked={showArchivedEmailSets} onChange={setShowArchiveEmailSetsFilter} />
    }, [setShowArchiveEmailSetsFilter, showArchivedEmailSets])

    const sortedEmailSets = useMemo(
        () =>
            orderBy(
                showArchivedEmailSets ? emailSets : reject(emailSets, es => es.isArchived),
                es => es.createdAt,
                'desc'
            ),
        [emailSets, showArchivedEmailSets]
    )

    const columns = useMemo<FilterColumnType<EmailSet>[]>(
        () => [
            {
                title: t('subject'),
                align: 'left',
                render: (_, { subject }) => subject,
            },
            {
                title: t('quantity'),
                width: 150,
                render: (_, { emails }) => emails.length,
            },
            {
                title: t('state'),
                render: (_, { emails }) => <EmailStatusTags emails={emails} />,
                width: 250,
            },
            {
                title: t('created'),
                render: (_, set) => humanCreatedAt(set),
            },
            {
                title: t('actions'),
                width: 150,
                align: 'right',
                render: (_, emailSet) => <EmailSetActions emailSet={emailSet} />,
            },
        ],
        [t]
    )

    return (
        <FilterTable<EmailSet>
            title={showArchivedEmailSetsFilter}
            dataSource={sortedEmailSets}
            rowKey="id"
            expandable={expandable}
            loading={loading}
            columns={columns}
        />
    )
}

type EmailSetActionsProps = {
    emailSet: EmailSet
}

const EmailSetActions: FC<EmailSetActionsProps> = ({ emailSet }) => {
    if (!emailSet.isArchived) {
        return <EmailSetArchiveControl emailSet={emailSet} />
    }
    return <EmailSetRestoreControl emailSet={emailSet} />
}

const useEmailSetArchiveControlTranslation = createUseTranslation({
    FR: {
        archive: 'Archiver',
    },
    EN: {
        archive: 'Archive',
    },
})

const emailSetIsArchivable = (emailSet: EmailSet) =>
    !emailSet.isArchived && !emailSet.emails.some(email => email.status !== 'READY')

type EmailSetArchiveControlProps = {
    emailSet: EmailSet
}

const EmailSetArchiveControl: FC<EmailSetArchiveControlProps> = ({ emailSet }) => {
    const { t } = useEmailSetArchiveControlTranslation()
    const [archiveEmailSetsMutation, { loading }] = useArchiveEmailSetsMutation()

    const enabled = useMemo(() => !loading && emailSetIsArchivable(emailSet), [emailSet, loading])

    const onClick = useAsyncCallback(async () => {
        await archiveEmailSetsMutation({
            variables: {
                ids: [emailSet.id],
            },
        })
    }, [archiveEmailSetsMutation, emailSet.id])

    return (
        <ActionButton disabled={!enabled} onClick={onClick}>
            {t('archive')}
        </ActionButton>
    )
}

const useEmailSetRestoreControlTranslation = createUseTranslation({
    FR: {
        restore: 'Rétablir',
    },
    EN: {
        restore: 'Restore',
    },
})

type EmailSetRestoreControlProps = {
    emailSet: EmailSet
}

const EmailSetRestoreControl: FC<EmailSetRestoreControlProps> = ({ emailSet }) => {
    const { t } = useEmailSetRestoreControlTranslation()
    const [unarchiveEmailSetsMutation, { loading }] = useUnarchiveEmailSetsMutation()
    const enabled = !loading

    const onClick = useAsyncCallback(async () => {
        await unarchiveEmailSetsMutation({
            variables: {
                ids: [emailSet.id],
            },
        })
    }, [emailSet.id, unarchiveEmailSetsMutation])

    return (
        <ActionButton disabled={!enabled} onClick={onClick}>
            {t('restore')}
        </ActionButton>
    )
}

type EmailTableForEmailSetProps = {
    mutable: boolean
    emailSet: EmailSet
}

const EmailTableForEmailSet: FC<EmailTableForEmailSetProps> = ({ mutable, emailSet }) => {
    const config = useMemo(() => ({ mutable }), [mutable])

    return (
        <EmailTable
            emails={emailSet.emails}
            config={config}
            emailActions={renderEmailActions}
            emailControls={renderEmailControls}
        />
    )
}

const renderEmailActions = (props: EmailActionsProps) => <EmailActions {...props} />
const renderEmailControls = (props: EmailControlsProps) => <EmailControls {...props} />

type ShowArchivedEmailSetsControlProps = {
    checked: boolean
    onChange: (value: boolean) => void
}

const useshowArchivedEmailSetsControlTranslation = createUseTranslation({
    EN: {
        filter: `Show archived emails`,
    },
    FR: {
        filter: `Afficher les emails archivés`,
    },
})

const ShowArchivedEmailSetsControl = ({ onChange, checked }: ShowArchivedEmailSetsControlProps) => {
    const { t } = useshowArchivedEmailSetsControlTranslation()

    return (
        <Space>
            <Switch checked={checked} onChange={onChange} />
            <span>{t('filter')}</span>
        </Space>
    )
}
