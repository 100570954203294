import { Checkbox, Form, Modal } from 'antd'
import saveAs from 'file-saver'
// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
import reduce from 'lodash/reduce'
import { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { fieldGroupLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { useApiClient } from '@publica/ui-common-network'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, icons } from '@publica/ui-web-components'

import { FieldGroup } from '../../../../../../data'
import { Operation } from '../../types'

const useExportParticipantValuesFormStyles = createUseStyles({
    form: {
        padding: [0, 36],
    },
})

type ExportParticipantValuesControlProps = {
    operation: Operation
}

type ExportParticipantValuesForm = Record<FieldGroup, boolean | undefined>

const exportParticipantValuesFormDefaults = fieldGroupLookup
    .keys()
    .reduce((values, key) => ({ ...values, [key]: true }), {} as Partial<ExportParticipantValuesForm>)

export const ExportParticipantValuesControl: FC<ExportParticipantValuesControlProps> = ({ operation }) => {
    const { t } = useExportParticipantValuesControlTranslation()
    const locale = useCurrentLocale()
    const styles = useExportParticipantValuesFormStyles()
    const apiClient = useApiClient()

    const [open, setOpen] = useState(false)
    const [inProgress, setInProgress] = useState(false)

    const [form] = Form.useForm<ExportParticipantValuesForm>()

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    const close = useCallback(() => {
        setOpen(false)
        setInProgress(false)
        form.resetFields()
    }, [form])

    const onOk = useAsyncCallback(async () => {
        setInProgress(true)

        const values = form.getFieldsValue()

        const fieldGroups = reduce(
            values,
            (fieldGroups, included, fieldGroup) => {
                if (included) {
                    return [...fieldGroups, fieldGroup as FieldGroup]
                }
                return fieldGroups
            },
            [] as FieldGroup[]
        )

        const data = await apiClient.participants.export({
            operationId: operation.id,
            fieldGroups,
        })

        const name = `${operation.name} - Export.xlsx`

        saveAs(data, name)

        close()
    }, [apiClient.participants, close, form, operation.id, operation.name])

    const okProps = useMemo(
        () => ({
            disabled: inProgress,
        }),
        [inProgress]
    )

    return (
        <>
            <ActionButton size="middle" icon={icons.Download} onClick={onClick}>
                {t('export')}
            </ActionButton>
            <Modal
                open={open}
                title={t('exportSelect')}
                onCancel={close}
                onOk={onOk}
                okText={t('exportOk')}
                okButtonProps={okProps}
            >
                <Form className={styles.form} form={form} initialValues={exportParticipantValuesFormDefaults}>
                    {fieldGroupLookup.entriesForLocale(locale).map(item => (
                        <Form.Item name={item.key} valuePropName="checked" required key={item.key}>
                            <Checkbox>{item.value}</Checkbox>
                        </Form.Item>
                    ))}
                </Form>
            </Modal>
        </>
    )
}

export const useExportParticipantValuesControlTranslation = createUseTranslation({
    EN: {
        export: 'Export participants',
        exportOk: 'Export',
        exportSelect: 'Pick export content',
    },
    FR: {
        export: 'Exporter participants',
        exportOk: 'Exporter',
        exportSelect: `Choisir le contenu de l'export`,
    },
})
