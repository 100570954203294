import { PaperClipOutlined } from '@ant-design/icons'
import { Form, Modal, Tag } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { allParticipantsGroupKey, operationIsMutable } from '@publica/common'
import { LocalizedString } from '@publica/locales'
import { attachmentTypeLookup, fieldGroupLookup } from '@publica/lookups'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { attachmentTypeLabel, fieldGroupLabel } from '@publica/ui-common-labels'
import { FC, removeTypeName, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, FilterColumnType, FilterTable, LookupSelect } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { FormRules } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import {
    AttachmentType,
    FieldGroup,
    GetOperationAttachmentsDocument,
    useCreateAttachmentMutation,
    useGetGroupsForOperationQuery,
    useGetOperationAttachmentsQuery,
    useUpdateAttachmentMutation,
} from '../../../../data'
import * as graphql from '../../../../data'
import { GroupFilter, GroupSelect, LocalizedStringInput } from '../../../components'

type Operation = Pick<graphql.Operation, 'id' | 'status'>
type Group = Pick<graphql.Group, 'id' | 'name' | 'key'>

export type AttachmentsIndexProps = {
    operation: Operation
}

const useAttachmentsIndexStyles = createUseStyles({
    filterContainer: {
        textAlign: 'right',
    },
})

export const AttachmentsIndex: FC<AttachmentsIndexProps> = ({ operation }) => {
    const { loading, data } = useGetOperationAttachmentsQuery({
        variables: {
            operationId: operation.id,
        },
        pollInterval: __SLOW_POLLING__,
    })

    const [groupFilter, setGroupFilter] = useState<string[]>([])

    const onChangeGroupFilter = useCallback((val: string[]) => {
        setGroupFilter(val)
    }, [])

    const styles = useAttachmentsIndexStyles()

    const attachments = useMemo(
        () =>
            (data?.operation?.attachments ?? []).filter(
                attachment =>
                    groupFilter.length === 0 || attachment.groups.some(group => groupFilter.includes(group.key))
            ),
        [data?.operation?.attachments, groupFilter]
    )
    const groups = data?.operation?.groups ?? []

    return (
        <>
            <AttachmentsControls operation={operation} />
            <div className={styles.filterContainer}>
                <GroupFilter groups={groups} onChange={onChangeGroupFilter} />
            </div>
            <AttachmentsTable operation={operation} loading={loading} attachments={attachments} />
        </>
    )
}

type AttachmentsControlsProps = {
    operation: Operation
}

const addAttachmentIcons = <PaperClipOutlined />

const useAttachmentControlTranslation = createUseTranslation({
    EN: {
        add: 'Add a KYC document',
    },
    FR: {
        add: 'Ajouter un document KYC',
    },
})

const AttachmentsControls: FC<AttachmentsControlsProps> = ({ operation }) => {
    const mutable = operationIsMutable(operation)
    const styles = utils.useControlsStyles()
    const [open, setOpen] = useState(false)
    const { t } = useAttachmentControlTranslation()

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
            <div className={styles.controls}>
                <ActionButton size="middle" onClick={onClick} disabled={!mutable} icon={addAttachmentIcons}>
                    {t('add')}
                </ActionButton>
            </div>
            <AttachmentForm
                title={t('add')}
                actionText={t('create')}
                open={open}
                onClose={onClose}
                operationId={operation.id}
            />
        </>
    )
}

type Attachment = {
    id: string
    name: LocalizedString
    type: AttachmentType
    group: FieldGroup
    groups: Group[]
}

type AttachmentsTableProps = {
    operation: Operation
    loading: boolean
    attachments: Attachment[]
}

const useAttachmentsTableTranslation = createUseTranslation({
    EN: {
        attachment: 'KYC Document',
        actions: 'Actions',
        group: 'Group',
        type: 'Type',
        groups: 'Groups',
    },
    FR: {
        attachment: 'Document KYC',
        actions: 'Actions',
        group: 'Groupe',
        type: 'Type',
        groups: 'Groupes',
    },
})

const AttachmentsTable: FC<AttachmentsTableProps> = ({ loading, attachments, operation }) => {
    const mutable = operationIsMutable(operation)
    const { t } = useAttachmentsTableTranslation()
    const resolveLocalizedString = useLocalizedStringResolver()

    const columns: FilterColumnType<Attachment>[] = useMemo(
        () => [
            {
                title: t('attachment'),
                render: (_, attachment) => resolveLocalizedString(attachment.name),
            },
            {
                title: t('group'),
                render: (_, attachment) => fieldGroupLabel(attachment.group),
            },
            // FIXME-P1(attachments-index): filter by type
            {
                title: t('type'),
                render: (_, attachment) => attachmentTypeLabel(attachment.type),
            },
            {
                title: t('groups'),
                render: (_, attachment) =>
                    attachment.groups.map(group => <Tag key={group.id}>{resolveLocalizedString(group.name)}</Tag>),
            },
            {
                title: t('actions'),
                render: (_, attachment) => (
                    <EditAttachment mutable={mutable} attachment={attachment} operation={operation} />
                ),
                align: 'right',
            },
        ],
        [mutable, operation, resolveLocalizedString, t]
    )

    return <FilterTable<Attachment> columns={columns} loading={loading} dataSource={attachments} rowKey="id" />
}

type AttachmentFormValues = {
    name: LocalizedString | undefined
    type: AttachmentType
    group: FieldGroup
    groups: { key: string }[]
}

type AttachmentFormProps = {
    open: boolean
    operationId: string
    onClose: () => void
    attachment?: Attachment
    title: string
    actionText: string
}

const useAttachmentFormTranslation = createUseTranslation({
    EN: {
        nameRequired: 'You must provide a name',
        name: 'Name',
        type: 'Type',
        group: 'Group',
        groups: 'Groups',
    },
    FR: {
        nameRequired: 'Vous devez fournir un nom',
        name: 'Nom',
        type: 'Type',
        group: 'Groupe',
        groups: 'Groupes',
    },
})

const AttachmentForm: FC<AttachmentFormProps> = ({ open, onClose, operationId, title, actionText, attachment }) => {
    const isUpdate = attachment !== undefined
    const [form] = Form.useForm<AttachmentFormValues>()
    const [createAttachmentMutation] = useCreateAttachmentMutation()
    const [updateAttachmentMutation] = useUpdateAttachmentMutation()
    const { t } = useAttachmentFormTranslation()

    const { data } = useGetGroupsForOperationQuery({
        variables: {
            operationId,
        },
    })

    const groups = data?.operation?.groups

    const formRules = useMemo<FormRules<AttachmentFormValues>>(() => {
        return {
            name: [
                {
                    required: true,
                    message: t('nameRequired'),
                },
            ],
            type: [
                {
                    required: true,
                },
            ],
            group: [
                {
                    required: true,
                },
            ],
            groups: [
                {
                    required: true,
                },
            ],
        }
    }, [t])

    const close = useCallback(() => {
        form.resetFields()
        onClose()
    }, [form, onClose])

    const getFormValues = useCallback(() => {
        const { name, type, group, groups } = form.getFieldsValue()

        assert.defined(name)
        assert.defined(type)
        assert.defined(group)

        return { name, type, group, groups }
    }, [form])

    const create = useCallback(async () => {
        const { name, type, group, groups } = getFormValues()

        await createAttachmentMutation({
            variables: {
                operationId,
                attachment: {
                    name,
                    type,
                    group,
                    groupKeys: groups.map(group => group.key),
                },
            },
            refetchQueries: [GetOperationAttachmentsDocument],
        })

        close()
    }, [close, createAttachmentMutation, getFormValues, operationId])

    const update = useCallback(async () => {
        const { name } = getFormValues()
        const id = attachment?.id

        assert.defined(id)

        await updateAttachmentMutation({
            variables: {
                id,
                attachment: {
                    name,
                },
            },
            refetchQueries: [GetOperationAttachmentsDocument],
        })

        close()
    }, [attachment?.id, close, getFormValues, updateAttachmentMutation])

    const onOk = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }

        if (isUpdate) {
            await update()
        } else {
            await create()
        }
    }, [create, form, isUpdate, update])

    const initialValues = useMemo<AttachmentFormValues>(
        () => ({
            type: attachment?.type ?? 'DOCUMENT',
            group: attachment?.group ?? 'PERSONAL_INFORMATION',
            name: removeTypeName(attachment?.name),
            groups: attachment?.groups ?? [{ key: allParticipantsGroupKey }],
        }),
        [attachment?.group, attachment?.groups, attachment?.name, attachment?.type]
    )

    return (
        <Modal open={open} onCancel={close} title={title} okText={actionText} onOk={onOk} destroyOnClose>
            <Form form={form} layout="vertical" initialValues={initialValues}>
                <Form.Item name="name" label={t('name')} required>
                    <LocalizedStringInput path="name" />
                </Form.Item>
                <Form.Item name="type" label={t('type')} hasFeedback rules={formRules.type}>
                    <LookupSelect disabled={isUpdate} lookup={attachmentTypeLookup} />
                </Form.Item>
                <Form.Item name="group" label={t('group')} hasFeedback rules={formRules.group}>
                    <LookupSelect disabled={isUpdate} lookup={fieldGroupLookup} />
                </Form.Item>
                <Form.Item name="groups" label={t('groups')} hasFeedback rules={formRules.groups}>
                    <GroupSelect disabled={isUpdate} groups={groups} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

type EditAttachmentProps = {
    mutable: boolean
    attachment: Attachment
    operation: Operation
}

const useEditAttachmentTranslation = createUseTranslation({
    EN: {
        title: 'Update document',
    },
    FR: {
        title: 'Modifier document',
    },
})

const EditAttachment: FC<EditAttachmentProps> = ({ mutable, attachment, operation }) => {
    const [open, setOpen] = useState(false)
    const { t } = useEditAttachmentTranslation()

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    const onClick = useCallback(() => {
        setOpen(true)
    }, [])

    return (
        <>
            <ActionButton disabled={!mutable} onClick={onClick}>
                {t('update')}
            </ActionButton>
            <AttachmentForm
                title={t('title')}
                actionText={t('update')}
                open={open}
                onClose={onClose}
                operationId={operation.id}
                attachment={attachment}
            />
        </>
    )
}
