import { Form } from 'antd'
import { Input, Modal } from 'antd'
import { Rule } from 'antd/lib/form'
import { useCallback, useEffect, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Subject, debounceTime } from 'rxjs'

import { allParticipantsGroupKey } from '@publica/common'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { removeTypeName, useAsyncCallback } from '@publica/ui-common-utils'
import { FC } from '@publica/ui-common-utils'
import { utils } from '@publica/ui-web-styles'
import { assert } from '@publica/utils'

import {
    FieldParameters,
    FieldParametersInput,
    GetOperationFieldsDocument,
    useCreateFieldMutation,
    useGetGroupsForOperationQuery,
    useUpdateFieldMutation,
} from '../../../../../data'
import * as graphql from '../../../../../data'
import { GroupSelect, LocalizedStringInput, SortedSelect } from '../../../../components'
import { FieldType, typeFromFieldParameter } from '../inputs'
import { fieldFormState, showFieldFormState } from '../state'
import { LocalizedString } from '../types'

type FormSection = Pick<graphql.FormSection, 'id' | 'name'>

type Group = Pick<graphql.Group, 'key'>

type FieldFormValues = {
    name: LocalizedString | undefined
    parameters?: FieldParameters | undefined
    formSectionId?: string | undefined
    groups: Group[]
    __type?: FieldType | undefined
}

export type FieldFormProps = {
    operation: Pick<graphql.Operation, 'id'>
    formSections: FormSection[]
}

const wrapFieldParametersForInput = (parameters: FieldParameters): FieldParametersInput => {
    switch (parameters.__typename) {
        case 'DateFieldParameters':
            return { date: removeTypeName(parameters) }
        case 'FloatFieldParameters':
            return { float: removeTypeName(parameters) }
        case 'LookupFieldParameters':
            return { lookup: removeTypeName(parameters) }
        case 'MapFieldParameters':
            return { map: removeTypeName(parameters) }
        case 'TextFieldParameters':
            return { text: removeTypeName(parameters) }
    }
}

const useFieldFormTranslation = createUseTranslation({
    EN: {
        add: '',
    },
    FR: {
        add: 'Ajouter champ actif',
    },
})

export const FieldForm: FC<FieldFormProps> = props => {
    const [fieldForm, setFieldForm] = useRecoilState(fieldFormState)
    const open = useRecoilValue(showFieldFormState)
    const isCreate = fieldForm?.field === null
    const { t } = useFieldFormTranslation()

    const title = isCreate ? t('add') : t('update')
    const actionText = isCreate ? t('create') : t('update')

    const submit = useMemo(() => new Subject<'submit'>(), [])

    const close = useCallback(() => {
        setFieldForm(undefined)
    }, [setFieldForm])

    const onOk = useCallback(() => {
        submit.next('submit')
    }, [submit])

    return (
        <Modal open={open} onCancel={close} title={title} okText={actionText} onOk={onOk} destroyOnClose>
            <InnerFieldForm key={fieldForm?.field?.id} {...props} close={close} submit={submit} />
        </Modal>
    )
}

type InnerFieldFormProps = {
    close: () => void
    submit: Subject<'submit'>
} & FieldFormProps

const useInnerFieldFormTranslation = createUseTranslation({
    EN: {
        formSection: `Form Section`,
        formSectionNote: `The form section determines where in the form the field will be displayed.`,
        formSectionRequired: 'You must choose a form section',
        name: `Name`,
        groups: 'Groups',
    },
    FR: {
        formSection: `Section du formulaire`,
        formSectionNote: `La section du formulaire définit là où apparaîtra le champ dans le formulaire présenté aux
        participants.`,
        formSectionRequired: 'Vous devez choisir une section',
        name: `Nom`,
        groups: 'Groupes',
    },
})

const InnerFieldForm: FC<InnerFieldFormProps> = ({ operation, close, submit, formSections }) => {
    const fieldForm = useRecoilValue(fieldFormState)
    const field = fieldForm?.field
    const group = fieldForm?.group
    const types = fieldForm?.types
    const requiresFormSection = fieldForm?.requiresFormSection ?? false
    const { t } = useInnerFieldFormTranslation()

    const isCreate = field === null
    const [form] = Form.useForm<FieldFormValues>()
    const [createFieldMutation] = useCreateFieldMutation()
    const [updateFieldMutation] = useUpdateFieldMutation()

    const { data } = useGetGroupsForOperationQuery({
        variables: {
            operationId: operation.id,
        },
    })

    const groups = data?.operation?.groups

    const resolveLocalizedString = useLocalizedStringResolver()

    const formStyles = utils.useFormStyles()

    const getFormValues = useCallback(() => {
        const { name, parameters: parametersBeforeWrapping, formSectionId, groups } = form.getFieldsValue()

        assert.defined(name)
        assert.defined(parametersBeforeWrapping)

        const parameters = wrapFieldParametersForInput(parametersBeforeWrapping)

        return { name, parameters, formSectionId, groups }
    }, [form])

    const create = useCallback(async () => {
        const { name, parameters, formSectionId, groups } = getFormValues()

        assert.defined(group)

        await createFieldMutation({
            variables: {
                operationId: operation.id,
                field: {
                    name,
                    group,
                    parameters,
                    formSectionId,
                    groupKeys: groups.map(group => group.key),
                },
            },
            refetchQueries: [GetOperationFieldsDocument],
        })

        close()
    }, [close, createFieldMutation, getFormValues, group, operation.id])

    const update = useCallback(async () => {
        const { name, formSectionId, parameters } = getFormValues()
        const id = field?.id

        assert.defined(id)

        await updateFieldMutation({
            variables: {
                id,
                field: {
                    name,
                    formSectionId,
                    parameters,
                },
            },
            refetchQueries: [GetOperationFieldsDocument],
        })

        close()
    }, [close, field?.id, getFormValues, updateFieldMutation])

    const onOk = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }

        if (isCreate) {
            await create()
        } else {
            await update()
        }
    }, [create, form, isCreate, update])

    useEffect(() => {
        const sub = submit.pipe(debounceTime(150)).subscribe({
            next: () => {
                onOk()
            },
        })

        return () => sub.unsubscribe()
    }, [onOk, submit])

    const formSectionLabel = useCallback(
        (formSection: FormSection) => resolveLocalizedString(formSection.name),
        [resolveLocalizedString]
    )

    const initialValues = useMemo<FieldFormValues>(
        () => ({
            name: removeTypeName(field?.name),
            formSectionId: field?.formSection?.id,
            parameters: field?.parameters,
            __type: typeFromFieldParameter(field?.parameters),
            groups: field?.groups ?? [{ key: allParticipantsGroupKey }],
        }),
        [field?.formSection?.id, field?.groups, field?.name, field?.parameters]
    )

    const formSectionRules = useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('formSectionRequired'),
            },
        ],
        [t]
    )

    return (
        <Form form={form} layout="vertical" initialValues={initialValues}>
            <Form.Item name="name" label={t('name')} required>
                <LocalizedStringInput path="name" />
            </Form.Item>
            <Form.Item name="parameters" hidden>
                <Input />
            </Form.Item>
            {requiresFormSection ? (
                <Form.Item label={t('formSection')} required hasFeedback>
                    <div className={formStyles.formLabelNote}>{t('formSectionNote')}</div>
                    <Form.Item
                        name="formSectionId"
                        className={formStyles.nestedFormItem}
                        hasFeedback
                        rules={formSectionRules}
                    >
                        <SortedSelect
                            items={formSections}
                            keyForItem={formSectionKey}
                            valueForItem={formSectionKey}
                            labelForItem={formSectionLabel}
                        />
                    </Form.Item>
                </Form.Item>
            ) : null}
            <FieldType types={types} disabled={!isCreate} />
            <Form.Item name="groups" label={t('groups')} hasFeedback required>
                <GroupSelect disabled={!isCreate} groups={groups} />
            </Form.Item>
        </Form>
    )
}

const formSectionKey = (formSection: FormSection) => formSection.id
