import { useCallback, useMemo } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, icons } from '@publica/ui-web-components'

import { useScheduleSendInvitationForParticipantsMutation } from '../../../../../../data'
import { participantsInvitationStatusState, selectedParticipantsState } from '../../state'
import { Operation } from '../../types'

type SendParticipantInvitesControlProps = {
    operation: Operation
}

export const SendParticipantInvitesControl: FC<SendParticipantInvitesControlProps> = ({ operation }) => {
    const { t } = useSendParticipantInvitationsControlTranslation()
    const [selectedParticipants, setSelectedParticipants] = useRecoilState(selectedParticipantsState)
    const setParticipantsInvitationStatus = useSetRecoilState(participantsInvitationStatusState)

    const label = useMemo(() => {
        if (selectedParticipants.length === 0) {
            return t('sendInvite_none')
        }
        return t('sendInvite', { count: selectedParticipants.length })
    }, [selectedParticipants.length, t])

    const disabled = selectedParticipants.length === 0

    const [scheduleSendInvitationForParticipantsMutation] = useScheduleSendInvitationForParticipantsMutation()

    const click = useCallback(() => {
        setSelectedParticipants([])
        setParticipantsInvitationStatus(state => ({
            ...state,
            ...selectedParticipants.reduce(
                (state, participant) => ({
                    ...state,
                    [participant.id]: {
                        isSending: true,
                        isCooling: false,
                    },
                }),
                {}
            ),
        }))

        void scheduleSendInvitationForParticipantsMutation({
            variables: {
                participantIds: selectedParticipants.map(p => p.id),
                operationId: operation.id,
            },
        }).then(() => {
            setParticipantsInvitationStatus(state => ({
                ...state,
                ...selectedParticipants.reduce(
                    (state, participant) => ({
                        ...state,
                        [participant.id]: {
                            isSending: false,
                            isCooling: true,
                        },
                    }),
                    {}
                ),
            }))
        })
    }, [
        operation.id,
        scheduleSendInvitationForParticipantsMutation,
        selectedParticipants,
        setParticipantsInvitationStatus,
        setSelectedParticipants,
    ])

    return (
        <ActionButton size="middle" onClick={click} icon={icons.AddUsers} disabled={disabled}>
            {label}
        </ActionButton>
    )
}

export const useSendParticipantInvitationsControlTranslation = createUseTranslation({
    EN: {
        sendInvite_none: 'Invite participants',
        sendInvite_one: 'Invite the participant',
        sendInvite_other: `Invite {{count}} participants`,
    },
    FR: {
        sendInvite_none: 'Inviter les participants',
        sendInvite_one: "Inviter le participant à l'opération",
        sendInvite_other: `Inviter {{count}} participants à l'opération`,
    },
})
