import { Divider } from 'antd'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'
import { Spinner, VerticalSpacer } from '@publica/ui-web-components'

import { Operation, useGetOperationFieldsQuery } from '../../../../data'
import { GroupFilter } from '../../../components'
import { FieldForm } from './FieldForm'
import { FieldGroup } from './FieldGroup'
import { FormSections } from './FormSections'
import { FieldType } from './inputs'

// FIXME(admin-ui): delete field

export type FieldsIndexProps = {
    operation: Pick<Operation, 'id' | 'status'>
}

const useFieldsIndexStyles = createUseStyles({
    filterContainer: {
        textAlign: 'right',
    },
})

export const FieldsIndex: FC<FieldsIndexProps> = ({ operation }) => {
    const { loading, data } = useGetOperationFieldsQuery({
        variables: {
            operationId: operation.id,
        },
        pollInterval: __SLOW_POLLING__,
    })

    const styles = useFieldsIndexStyles()

    const [groupFilter, setGroupFilter] = useState<string[]>([])

    const onChangeGroupFilter = useCallback((val: string[]) => {
        setGroupFilter(val)
    }, [])

    const fields = useMemo(
        () =>
            sortBy(
                (data?.operation?.fields ?? []).filter(
                    field => groupFilter.length === 0 || field.groups.some(group => groupFilter.includes(group.key))
                ),
                field => field.position
            ),
        [data?.operation?.fields, groupFilter]
    )

    const groups = data?.operation?.groups ?? []

    if (loading) {
        return <Spinner />
    }

    const formSections = data?.operation?.formSections ?? []

    return (
        <>
            <div className={styles.filterContainer}>
                <GroupFilter groups={groups} onChange={onChangeGroupFilter} />
            </div>
            <VerticalSpacer size={10}>
                <FieldGroup group="PERSONAL_INFORMATION" fields={fields} operation={operation} requiresFormSection />
                <FieldGroup group="BANKING_INFORMATION" fields={fields} operation={operation} requiresFormSection />
                <FieldGroup
                    group="FINANCIAL_INFORMATION"
                    fields={fields}
                    types={financialInformationTypes}
                    operation={operation}
                />
                <Divider />
                <FormSections formSections={formSections} operation={operation} />
            </VerticalSpacer>
            <FieldForm operation={operation} formSections={formSections} />
        </>
    )
}

const financialInformationTypes: FieldType[] = ['float.float', 'text.plain']
