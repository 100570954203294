import { saveAs } from 'file-saver'
import { useMemo } from 'react'

import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { useApiClient } from '@publica/ui-common-network'
import { FC } from '@publica/ui-common-utils'
import { useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, FilterColumnType, FilterTable } from '@publica/ui-web-components'
import { participant } from '@publica/ui-web-styles'
import { useAttachmentsWithUploads } from '@publica/ui-web-utils'

import * as graphql from '../../../data'

type Attachment = Pick<graphql.Attachment, 'id' | 'name'>

type UploadedAttachment = Pick<graphql.UploadedAttachment, 'id' | 'fileName'> & {
    attachment: Pick<graphql.Attachment, 'id'>
}

type AttachmentWithUploads = Attachment & {
    uploadedAttachments: UploadedAttachment[]
}

export type AttachmentTableProps = {
    attachments: Attachment[]
    uploadedAttachments: UploadedAttachment[]
}

const useAttachmentTableTranslation = createUseTranslation({
    FR: {
        name: 'Nom',
        actions: 'Actions',
        noAttachment: 'Aucun document KYC fourni',
        download_one: 'Télécharger document',
        download_other: 'Télécharger {{count}} documents',
    },
    EN: {
        name: 'Name',
        actions: 'Actions',
        noAttachment: 'No KYC document provided',
        download_one: 'Download document',
        download_other: 'Download {{count}} documents',
    },
})

export const AttachmentTable: FC<AttachmentTableProps> = ({ attachments, uploadedAttachments }) => {
    const attachmentsWithUploads = useAttachmentsWithUploads(attachments, uploadedAttachments, true)
    const { t } = useAttachmentTableTranslation()
    const resolveLocalizedString = useLocalizedStringResolver()

    const columns = useMemo<FilterColumnType<AttachmentWithUploads>[]>(
        () => [
            {
                title: t('name'),
                render: (_, attachment) => resolveLocalizedString(attachment.name),
            },
            {
                title: t('actions'),
                render: (_, { uploadedAttachments }) => {
                    if (uploadedAttachments.length === 0) {
                        return <NoUploadedAttachment />
                    } else {
                        return <UploadedAttachmentControls uploadedAttachments={uploadedAttachments} />
                    }
                },
                align: 'right',
            },
        ],
        [resolveLocalizedString, t]
    )

    return <FilterTable<AttachmentWithUploads> columns={columns} dataSource={attachmentsWithUploads} />
}

const NoUploadedAttachment: FC = () => {
    const styles = participant.useNoValueStyles()
    const { t } = useAttachmentTableTranslation()
    return <div className={styles.noValue}>{t('noAttachment')}</div>
}

type UploadedAttachmentControlsProps = {
    uploadedAttachments: UploadedAttachment[]
}

const UploadedAttachmentControls: FC<UploadedAttachmentControlsProps> = ({ uploadedAttachments }) => {
    const client = useApiClient()
    const { t } = useAttachmentTableTranslation()

    const download = useAsyncCallback(async () => {
        for (const uploadedAttachment of uploadedAttachments) {
            const data = await client.attachments.download(uploadedAttachment.id)
            saveAs(data, uploadedAttachment.fileName)
        }
    }, [client.attachments, uploadedAttachments])

    // FIXME(admin-ui, attachments): view document

    return <ActionButton onClick={download}>{t('download', { count: uploadedAttachments.length })}</ActionButton>
}
