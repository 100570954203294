import { Card, Collapse } from 'antd'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

// import { AddParticipantsManually } from './AddParticipantsManually'
import { ImportParticipantsFromExcel } from './ImportParticipantsFromExcel'

type AddParticipantsProps = {
    operationId: string
}

const useAddParticipantsTranslation = createUseTranslation({
    EN: {
        invite: 'Add participants to the operation',
        manual: 'Add manually',
        file: 'Upload a file',
    },
    FR: {
        invite: `Rajouter des participants à l'opération`,
        manual: 'Gérer manuellement',
        file: 'Télécharger un fichier',
    },
})
// TODO(add-participants): manual add
// TODO(add-participants): select existing
export const AddParticipants: FC<AddParticipantsProps> = ({ operationId }) => {
    const { t } = useAddParticipantsTranslation()

    return (
        <Card title={t('invite')}>
            <Collapse defaultActiveKey="bulk" accordion={true}>
                <Collapse.Panel key="bulk" header={t('file')}>
                    <ImportParticipantsFromExcel operationId={operationId} />
                </Collapse.Panel>
                {/* <Collapse.Panel key="manual" header={t('manual')}>
                    <AddParticipantsManually operationId={operationId} />
                </Collapse.Panel> */}
            </Collapse>
        </Card>
    )
}
