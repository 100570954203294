import { Tag } from 'antd'
import { ReactNode } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { TagField } from '@publica/ui-web-components'
import { lang } from '@publica/utils'

import { Participant } from '../../types'

type ParticipantStatusTagsProps = {
    participant: Participant
}

const useParticipantStatusTagsTranslation = createUseTranslation({
    EN: {
        missingValues: 'Some personal information is missing',
        noMissingValues: 'All the personal information has been provided',
        missingAttachments: 'Some KYC documents are missing',
        noMissingAttachments: 'All the KYC documents have been provided',
        pendingSignatures: 'There are documents awaiting signature',
        pendingInvitation: 'Invitation not yet sent',
    },
    FR: {
        missingValues: "La fiche d'informations personnelles est incomplète",
        noMissingValues: "La fiche d'informations est complète",
        missingAttachments: 'Des documents KYC sont manquants',
        noMissingAttachments: 'Tous les documents KYC ont été transmis',
        pendingSignatures: 'Des documents sont en attente de signature',
        pendingInvitation: "Pas encore invité à l'opération",
    },
})

export const ParticipantStatusTags: FC<ParticipantStatusTagsProps> = ({ participant }) => {
    const { t } = useParticipantStatusTagsTranslation()

    const pendingInvitation = participant.lastInvitationSentAt === undefined

    if (pendingInvitation) {
        return (
            <TagField>
                <Tag key="pendingInvitation">{t('pendingInvitation')}</Tag>
            </TagField>
        )
    }

    const tags: ReactNode[] = []

    let hasMissingValues = false
    let hasMissingAttachments = false
    let hasPendingSignatures = false

    for (const notification of participant.notifications) {
        if (hasPendingSignatures && hasMissingAttachments && hasMissingValues) {
            break
        }

        switch (notification.__typename) {
            case 'MissingValuesNotification':
                if (notification.group === 'PERSONAL_INFORMATION') {
                    hasMissingValues = true
                }
                break

            case 'MissingAttachmentsNotification':
                hasMissingAttachments = true
                break

            case 'RequiredSignaturesNotification':
                hasPendingSignatures = true
                break

            default:
                throw lang.NotExhaustedError(notification)
        }
    }

    if (hasMissingValues) {
        tags.push(
            <Tag key="missingValues" color="warning">
                {t('missingValues')}
            </Tag>
        )
    } else {
        tags.push(
            <Tag key="missingValues" color="success">
                {t('noMissingValues')}
            </Tag>
        )
    }

    if (hasMissingAttachments) {
        tags.push(
            <Tag key="missingAttachments" color="warning">
                {t('missingAttachments')}
            </Tag>
        )
    } else {
        tags.push(
            <Tag key="missingAttachments" color="success">
                {t('noMissingAttachments')}
            </Tag>
        )
    }

    if (hasPendingSignatures) {
        tags.push(
            <Tag key="pendingSignatures" color="warning">
                {t('pendingSignatures')}
            </Tag>
        )
    }

    return <TagField>{tags}</TagField>
}
